html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  background-color: antiquewhite;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}


.my-data-grid .custom-data-grid .MuiDataGrid-cell {
  border: 1px solid #ccc;
  padding: 8px;
}

.my-data-grid .custom-data-grid .MuiDataGrid-colCellTitle,
.my-data-grid .custom-data-grid .MuiDataGrid-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}